import { Routes, Route, HashRouter as Router } from "react-router-dom";
import { DeferredPaymentProvider } from "./context/DeferredPaymentContext";
import { RetrievalPaymentProvider } from "./context/RetrievalPaymentContext";
import { UserProvider } from "./context/UserContext";

import { AddFunds } from "./pages/AddFunds";
import { Cart } from "./pages/Cart";
import { Home } from "./pages/Home";
import { NewUserCheckout } from "./pages/NewUserCheckout";
import { ReturningUser } from "./pages/ReturningUser";
import { ReturningUserCheckout } from "./pages/ReturningUserCheckout";
import { SignUp } from "./pages/SignUp";
import { SignUpCompleted } from "./pages/SignUpCompleted";
import { SignUpReview } from "./pages/SignUpReview";
import { DepositNewUser } from "./pages/DepositNewUser";
import { DepositReturningUser } from "./pages/DepositReturningUser";
import { OnboardingNewUser } from "./pages/OnboardingNewUser";
import { Cashless } from "./pages/Cashless";
import { BillingNewUser } from "./pages/BillingNewUser";

function App() {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/cashless" element={<Cashless />} />
          <Route path="/wallet" element={<DeferredPaymentProvider />}>
            <Route path="new" element={<AddFunds />} />
            <Route path="returning" element={<ReturningUser />} />
          </Route>
          <Route path="/onboarding" element={<DeferredPaymentProvider />}>
            <Route path="" element={<OnboardingNewUser />} />
          </Route>
          <Route path="/billing" element={<DeferredPaymentProvider />}>
            <Route path="" element={<BillingNewUser />} />
          </Route>
          <Route path="/deposit" element={<DeferredPaymentProvider />}>
            <Route path="new" element={<DepositNewUser />} />
            <Route path="returning" element={<DepositReturningUser />} />
          </Route>
          <Route path="/signup" element={<RetrievalPaymentProvider />}>
            <Route path="" element={<SignUp />} />
            <Route path="review" element={<SignUpReview />} />
            <Route path="completed" element={<SignUpCompleted />} />
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/checkout" element={<DeferredPaymentProvider />}>
            <Route path="new" element={<NewUserCheckout />} />
            <Route path="returning" element={<ReturningUserCheckout />} />
          </Route>
          
        </Routes>
      </Router>
    </UserProvider>
  );
}

export default App;
