import { useContext, useEffect } from "react";
import { v4 as uuid } from "uuid";

import { BankInformation } from "../BankInformation";
import { TrustlyFlow } from "../TrustlyFlow";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";

import { getTransaction } from "../../api";
import { queryStringToJson } from "../../utils/queryStringToJson";
import { RadioInput } from "../atoms/RadioInput";
import { AddBankButton } from "../atoms/AddBankButton";

export function PaymentOption({ selected, disabled, type, depositAmount, merchantName }) {
  const {
    paymentDetails,
    updatePaymentDetails,
    hasUserLoggedIn,
    toggleHasUserLoggedIn,
    toggleHasUserCanceled,
    paymentFlow,
  } = useContext(DeferredPaymentContext);

  async function handleBankInfoRetrieval(transactionId) {
    // const transaction = await getTransaction(transactionId)
    //   .then((response) => response.data);

    // updatePaymentDetails({
    //   transactionId: transaction.transactionId,
    //   bankName: transaction.payment.paymentProvider.name,
    //   bankId: transaction.payment.paymentProvider.paymentProviderId,
    //   accountNumber: transaction.payment.account.accountNumber,
    //   merchantReference: transaction.payment.merchantReference,
    //   dummyAccount: false,
    // });
    updatePaymentDetails({
      transactionId,
      bankName: "Demo Bank",
      bankId: "200005501",
      accountNumber: "6576",
      merchantReference: uuid(),
      dummyAccount: true,
    });

    toggleHasUserLoggedIn();
  }

  function handleLightBoxClosing() {
    window.Trustly.addPanelListener((command, obj) => {
      if (obj?.type === "new_location") {
        obj.preventDefault();

        if (obj?.data.indexOf("#return") === 0) {
          const { transactionId } = queryStringToJson(obj?.data);

          handleBankInfoRetrieval(transactionId);
        }

        if (obj?.data.indexOf("#cancel") === 0) {
          toggleHasUserCanceled();
        }
      }
    });
  }

  useEffect(() => {
    if (
      !hasUserLoggedIn &&
      type === "onlineBanking" &&
      paymentFlow !== "returning"
    ) {
      handleLightBoxClosing();
    }
  }, []);

  return (
    <>
      <div
        className={`${
          selected ? "rounded-t-md" : "rounded-md"
        } border border-neutral-200 px-4 min-h-[60px] flex justify-between`}
      >
        <div className="flex gap-3 items-center relative">
          <RadioInput id={type} selected={selected} disabled={disabled} />
          <span>
            {type === "onlineBanking" ? "Online Banking" : "Credit/debit card"}
          </span>
        </div>
        {type === "onlineBanking" && (
          <img
            width="90"
            src="https://paywithmybank.com/start/asset/icon_trustly-136x82_light_en-US.png"
            alt=""
          />
        )}
      </div>
      {type === "onlineBanking" && (
        <>
          {!hasUserLoggedIn ? (
            <TrustlyFlow renderMethod="widget" paymentType="Deferred" depositAmount={depositAmount} merchantName={merchantName} />
          ) : (
            <div
              className={`border border-neutral-200 border-t-0 rounded-b-md p-4`}
            >
              <BankInformation bankDetails={paymentDetails} />
              <AddBankButton paymentType={"Deferred"} depositAmount={depositAmount} />
            </div>
          )}
        </>
      )}
    </>
  );
}
