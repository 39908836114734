import axios from 'axios';
import { v4 as uuid } from 'uuid';
import requestSignatureGenerator from './utils/requestSignatureGenerator';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL || "http://localhost:3000" });

export async function getEstablishData(paymentType, displayAmount, trustlyProduct, merchantName) {
  const establishData = {
    currency: 'USD',
    amount: '0.00',
    paymentType,
    returnUrl: '#return',
    cancelUrl: '#cancel',
  };

  const isTrustlyId = trustlyProduct === 'trustlyId';

  if (isTrustlyId) {
    establishData.kycType = 1;
    establishData.metadata = {
      flowType: "ID_CAPTURE"
    };
  }

  if (displayAmount !== "") {
    console.log(displayAmount);
    establishData.displayAmount = `${displayAmount}.00`;
  }

  const merchantIds = {
    "bet.on": "1255",
    "shop.on": "1029758675"
  };

  establishData.accessId = process.env.REACT_APP_TRUSTLY_ACCESS_ID;
  establishData.merchantId = merchantName ? merchantIds[merchantName.toLowerCase()] : process.env.REACT_APP_TRUSTLY_MERCHANT_ID;
  establishData.merchantReference = uuid();


  const requestSignature = requestSignatureGenerator(establishData);

  establishData.requestSignature = requestSignature;

  try {
    // const updatedRequestSignature = await api.post("/signature", establishData)
    //   .then(response => response.data);

    // return updatedRequestSignature;

    return establishData;

  } catch (error) {
    if (error.response) {
      console.log(error.response);
    }
  }
}

export async function getTransaction(transactionId) {
  return api.get(`/transactions/${transactionId}`);
}

export async function captureTransaction(transactionId, merchantReference, amount) {
  return api.post(`/transactions/${transactionId}/capture`, {
    merchantReference,
    amount
  });
}

export async function getUser(transactionId) {
  return api.get(`/transactions/${transactionId}/user`);
}

export async function getAccountBalance(transactionId) {
  return api.get(`/transactions/${transactionId}/account/balance`);
}