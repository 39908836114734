import { useContext, useState } from "react";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";
import { TrustlyFlow } from "../TrustlyFlow";

export function OnboardingButton({ depositAmount, text = "Deposit Now" }) {
    const [isLightBoxRendered, setIsLightBoxRendered] = useState(false);
    const { paymentDetails, paymentFlow, toggleIsCaptureProcessing, toggleIsCaptureCompleted, toggleHasUserCanceled } = useContext(DeferredPaymentContext);

    function handleLightBoxClosing() {
        window.Trustly.addPanelListener((command, obj) => {
            // if (obj?.type === "load" && obj?.page === "login") {
            //     window["lbx-iframeAuthenticate"].contentDocument.getElementById("lbx-formAuthenticate-authFields-inputpassword").value = "TrustlyId";
            // }
            if (obj?.type === "new_location" && obj.data.indexOf("#return") === 0) {
                obj.preventDefault();
                setIsLightBoxRendered(false)

                // const { transactionId } = queryStringToJson(obj.data);

                // navigate('/signup/review', { state: { transactionId } });

                toggleIsCaptureProcessing();

                setTimeout(() => {
                    toggleIsCaptureProcessing();
                    toggleIsCaptureCompleted();
                    paymentFlow === "returning" && localStorage.removeItem("Trustly.paymentDetails")
                }, paymentDetails.dummyAccount && 3000)
            }

            if (obj?.type === "new_location" && obj.data.indexOf("#cancel") === 0) {
                obj.preventDefault();
                setIsLightBoxRendered(false);
                toggleHasUserCanceled();
            }

            
        });
    }

    function handleSignUpClick() {
        setIsLightBoxRendered(true);
        handleLightBoxClosing();
    }

    async function handleCapture() {
        toggleIsCaptureProcessing();

        setTimeout(() => {
            toggleIsCaptureProcessing();
            toggleIsCaptureCompleted();
            paymentFlow === "returning" && localStorage.removeItem("Trustly.paymentDetails")
        }, paymentDetails.dummyAccount && 3000)

    }

    return (
        <>
            <button
                type="button"
                onClick={handleSignUpClick}
                disabled={!depositAmount}
                className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
            >
                {text}
            </button>

            {isLightBoxRendered && <TrustlyFlow paymentType="Retrieval" depositAmount={depositAmount} trustlyProduct={"trustlyId"} />}
        </>
    );
}