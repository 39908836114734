import { DepositButton } from "../atoms/DepositButton";
import { Subtitle } from "../atoms/Subtitle";

export function DepositAmountSelector({ depositAmount, setDepositAmount }) {
  const depositValues = [
    { amount: "10", rounded: "l"},
    { amount: "25" },
    { amount: "100" },
    { amount: "250", rounded: "r"},
  ];

  function handleDepositAmountChange(depositAmount) {
    setDepositAmount(depositAmount);
  }

  return (
    <div>
      <Subtitle title="Choose a deposit amount" />

      <div className="flex divide-x divide-x-reverse">
        { depositValues.map(deposit => (
          <DepositButton
            key={deposit.amount}
            selected={depositAmount === deposit.amount}
            setDepositAmount={handleDepositAmountChange}
            {...deposit}
          />
        ))}
      </div>
    </div>
  );
}
