import { useContext, useState } from "react";

import { Title } from "../atoms/Title";
import { DepositAmountSelector } from "../organisms/DepositAmountSelector";
import { PaymentMethods } from "../organisms/PaymentMethods";
import { LoadingScreen } from "../organisms/LoadingScreen";
import { SuccessfulScreen } from "../organisms/SuccessfulScreen";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";
import { CanceledScreen } from "../organisms/CanceledScreen";
import { Button } from "../atoms/Button";
import { Subtitle } from "../atoms/Subtitle";

import paymentMarks from "../../assets/payment-marks.png";
import { OnboardingButton } from "../atoms/OnboardingButton";

export function OnboardingFlow() {
    const [depositAmount, setDepositAmount] = useState("");
    const { isCaptureProcessing, isCaptureCompleted, hasUserCanceled } =
        useContext(DeferredPaymentContext);
    return (
        <>
            {!isCaptureProcessing && isCaptureCompleted ? (
                <SuccessfulScreen title="Deposit completed" />
            ) : isCaptureProcessing ? (
                <LoadingScreen title="Processing your deposit" />
            ) : hasUserCanceled ? (
                <CanceledScreen title="Deposit canceled" />
            ) : (
                <>
                    <Title title="Kickstart your gaming" />
                    <div className="grid grid-cols-1 max-w-1-5xl gap-6">
                        <DepositAmountSelector depositAmount={depositAmount} setDepositAmount={setDepositAmount} />

                        <div className="py-4 px-3 text-sky-700 bg-sky-50 rounded-lg">
                            <span className="text-base ">Before you can legally make your first deposit we need to identify your identity</span>
                        </div>


                        <div className="grid grid-cols-1 gap-3">
                            <Subtitle title="Choose a verification option" color="black" weight="bold" margin={false} />
                            <div className="py-5 px-3 border-solid border-2 border-green-800 rounded-lg flex items-start gap-2.5 leading-3.5 relative">
                                <input className="shrink-0 appearance-none w-4.5 h-4.5 border-solid border-2 border-green-800 rounded-full mt-0.5 relative before:content-[''] before:h-[9px] before:w-[9px] before:block before:bg-green-800 before:absolute before:top-1/2 before:left-1/2 before:translate-y-[-50%] before:translate-x-[-50%] before:rounded-full" type="checkbox" />
                                <div className="flex flex-col items-start">
                                    <span className="text-sm font-semibold">Verify & deposit now</span>
                                    <p className="text-xs mt-1.5 mb-3">Sign into your bank account for express verification and payment, all in one go</p>
                                    <img className="max-w-[190px]" src={"https://paywithmybank.com/assets/merchant-logos/demo-payment-mark.png"} alt="" />
                                </div>

                                <span className="absolute top-0 right-0 bg-green-800 uppercase py-0.5 px-1.5 rounded-tr rounded-bl text-white font-bold tracking-wide font-montserrat tracking-[.026px] text-[12.96px]">Fastest</span>
                            </div>
                            <div className="py-5 px-3 border-solid border-2 border-slate-250 rounded-lg flex items-start gap-2.5 leading-3.5">
                                <input className="shrink-0 appearance-none w-4.5 h-4.5 border-solid border-2 border-zinc-350 rounded-full mt-0.5" type="checkbox" />
                                <div className="flex flex-col items-start">
                                    <span className="text-sm font-semibold">Manual verification</span>
                                    <p className="text-xs mt-1.5">Enter your information manually, then select a payment method</p>
                                </div>
                            </div>
                        </div>

                        <OnboardingButton text="Continue" depositAmount={depositAmount} />
                    </div>
                </>
            )}
        </>
    );
}
