import { useEffect } from "react";
import { getEstablishData } from "../api";

export function TrustlyFlow({ renderMethod, paymentType, depositAmount, trustlyProduct, merchantName }) {
  const Trustly = window.Trustly;

  const TrustlyOptions = {
    closeButton: false,
    dragAndDrop: false,
    widgetContainerId: "widget-container-id"
  };

  async function renderTrustlyFlow() {
    const establishData = await getEstablishData(paymentType, depositAmount, trustlyProduct, merchantName);

    if (renderMethod === "widget") {
      Trustly.selectBankWidget(establishData, TrustlyOptions);
    } else {
      Trustly.establish(establishData, TrustlyOptions);
    }
  }

  useEffect(() => {
    renderTrustlyFlow();
  }, [depositAmount]);


  return (
    <>
      { renderMethod === "widget" && (
        <div
          id="widget-container-id"
          className={`border border-neutral-200 border-t-0 rounded-b-md p-4`}
        ></div>
      )}
    </>
  );
}