import { useContext, useState } from "react";

import { Title } from "../atoms/Title";
import { DepositAmountSelector } from "../organisms/DepositAmountSelector";
import { PaymentMethods } from "../organisms/PaymentMethods";
import { LoadingScreen } from "../organisms/LoadingScreen";
import { SuccessfulScreen } from "../organisms/SuccessfulScreen";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";
import { CanceledScreen } from "../organisms/CanceledScreen";
import { Button } from "../atoms/Button";

export function DepositFlow() {
  const [depositAmount, setDepositAmount] = useState("");
  const { isCaptureProcessing, isCaptureCompleted, hasUserCanceled } =
    useContext(DeferredPaymentContext);
  return (
    <>
      {!isCaptureProcessing && isCaptureCompleted ? (
        <SuccessfulScreen title="Deposit completed" />
      ) : isCaptureProcessing ? (
        <LoadingScreen title="Processing your deposit" />
      ) : hasUserCanceled ? (
        <CanceledScreen title="Deposit canceled" />
      ) : (
        <>
          <Title title="Deposit" />
          <div className="grid grid-cols-1 max-w-2xl gap-6">
            <DepositAmountSelector depositAmount={depositAmount} setDepositAmount={setDepositAmount} />

            <PaymentMethods title={"Choose a deposit method"} depositAmount={depositAmount} />

            <Button depositAmount={depositAmount} />
          </div>
        </>
      )}
    </>
  );
}
