import { useState } from "react";
import { Link } from "react-router-dom";

import { FlowBox } from "../components/FlowBox";
import { Label } from "../components/atoms/Label";
import { Modal } from "../components/Modal";
import { Header } from "../components/Header";

import girlWithCatImage from "../assets/girl-with-cat.svg";

export function Home() {
  const [openDigitalWalletModal, setOpenDigitalWalletModal] = useState(false);
  const [openPurchaseSnickersModal, setOpenPurchaseSnickersModal] = useState(false);
  const [openCashlessTransactionModal, setOpenCashlessTransactionModal] = useState(false);
  const [openFakeCashlessModal, setOpenFakeCashlessModal] = useState(false);
  const [openGamingDepositModal, setOpenGamingDepositModal] = useState(false);
  const [openOnboardingModal, setOpenOnboardingModal] = useState(false);
  const [openBillingModal, setOpenBillingModal] = useState(false);
  return (
    <div className="w-full h-full bg-green-900 antialiased">
      <Header />
      <div className="container px-6 lg:px-6  mx-auto mb-10 relative z-10">
        <div className="mb-6 md:mb-4 lg:mb-6 mt-9 md:mt-6 lg:mt-8">
          <h1 className="text-green-400 text-4xl font-bold font-trustly mb-8">
            Trustly Use <Link to={"/cashless"}>Cases</Link>

          </h1>
          <span className="text-neutral-150 leading-5 font-trustly">
            What would you like to do?
          </span>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-2 md:gap-8">
          {process.env.REACT_APP_BILLING_ENABLED === "true" && (
            <FlowBox>
              <Link to={"/billing"} className="flex flex-col items-start w-full">
                <strong className="font-semibold leading-5 mb-4 block">
                  Pay Bills
                </strong>
                <Label colorClass="bg-purple-100">Payments</Label>
              </Link>
            </FlowBox>

          )}

          {process.env.REACT_APP_ONBOARDING_ENABLED === "true" && (
            <FlowBox>
              <Link to={"/onboarding"} className="flex flex-col items-start w-full">
                <strong className="font-semibold leading-5 mb-4 block">
                  Onboarding
                </strong>
                <Label colorClass="bg-yellow-100">Data</Label>
              </Link>
            </FlowBox>

          )}

          {process.env.REACT_APP_GAMING_DEPOSIT_ENABLED === "true" && (
            <FlowBox>
              <button
                className="flex flex-col items-start w-full"
                onClick={() => setOpenGamingDepositModal(true)}
              >
                <strong className="font-semibold leading-5 mb-4 block">
                  Make a deposit
                </strong>
                <Label colorClass="bg-purple-100">Payments</Label>
              </button>
            </FlowBox>

          )}

          {process.env.REACT_APP_ADD_FUNDS_TO_WALLET_ENABLED === "true" && (
            <FlowBox>
              <button
                className="flex flex-col items-start w-full"
                onClick={() => setOpenDigitalWalletModal(true)}
              >
                <strong className="font-semibold leading-5 mb-4 block">
                  Add funds to digital wallet
                </strong>
                <Label colorClass="bg-purple-100">Payments</Label>
              </button>
            </FlowBox>
          )}

          {process.env.REACT_APP_PURCHASE_SNICKERS_ENABLED === "true" && (
            <FlowBox>
              <button
                className="flex flex-col items-start w-full"
                onClick={() => setOpenPurchaseSnickersModal(true)}
              >
                <strong className="font-semibold leading-5 mb-4 block">
                  Purchase Sneakers
                </strong>
                <Label colorClass="bg-purple-100">Payments</Label>
              </button>
            </FlowBox>
          )}

          {process.env.REACT_APP_MANAGE_FINANCES_ENABLED === "true" && (
            <FlowBox>
              <Link to={"/signup"} className="flex flex-col items-start w-full">
                <strong className="font-semibold leading-5 mb-4 block">
                  Manage your finances
                </strong>
                <Label colorClass="bg-yellow-100">Data</Label>
              </Link>
            </FlowBox>
          )}

          {process.env.REACT_APP_CASHLESS_TRANSACTION_ENABLED === "true" && (
            <FlowBox>
              <button
                className="flex flex-col items-start w-full"
                onClick={() => setOpenCashlessTransactionModal(true)}
              >
                <strong className="font-semibold leading-5 mb-4 block">
                  Cashless Transaction
                </strong>
                <Label colorClass="bg-purple-100">Payments</Label>
              </button>
            </FlowBox>
          )}

          {process.env.REACT_APP_FAKE_CASHLESS_ENABLED === "true" && (
            <FlowBox>
              <button
                className="flex flex-col items-start w-full"
                onClick={() => setOpenFakeCashlessModal(true)}
              >
                <strong className="font-semibold leading-5 mb-4 block">
                  Cashless Transaction
                </strong>
                <Label colorClass="bg-purple-100">Payments</Label>
              </button>
            </FlowBox>
          )}
        </div>
      </div>

      <img
        src={girlWithCatImage}
        className="w-full px-6 max-w-[12rem] 2xs:max-w-none xs:px-0 xs:w-80 md:w-128 md:pr-9 md:pb-8 md:right-0 xl:w-152 xl:pr-14 xl:pb-20 landscape:hidden lg:landscape:block absolute bottom-0 left-1/2 md:left-auto -translate-x-1/2 md:translate-x-0"
        alt="Girl sitting on the couch with her cat"
      />

      <Modal
        open={openOnboardingModal}
        setOpen={setOpenOnboardingModal}
        scenario="onboarding"
      />

      <Modal
        open={openBillingModal}
        setOpen={setOpenBillingModal}
        scenario="billing"
      />

      <Modal
        open={openGamingDepositModal}
        setOpen={setOpenGamingDepositModal}
        scenario="gamingDeposit"
      />

      <Modal
        open={openDigitalWalletModal}
        setOpen={setOpenDigitalWalletModal}
        scenario="digitalWallet"
      />
      <Modal
        open={openPurchaseSnickersModal}
        setOpen={setOpenPurchaseSnickersModal}
        scenario="purchaseSnickers"
      />

      <Modal
        open={openCashlessTransactionModal}
        setOpen={setOpenCashlessTransactionModal}
        scenario="cashlessTransaction"
      />

      <Modal
        open={openFakeCashlessModal}
        setOpen={setOpenFakeCashlessModal}
        scenario="fakeCashless"
      />
    </div>
  );
}
