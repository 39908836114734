import { useState } from "react";
import { createContext } from "react";
import { Outlet } from "react-router-dom";

export const DeferredPaymentContext = createContext();

export function DeferredPaymentProvider({ children }) {
  const [paymentDetails, setPaymentDetails] = useState(() => {
    const storedPaymentDetails = localStorage.getItem("Trustly.paymentDetails");
    if (!storedPaymentDetails) {
      return {
        accountNumber: "6576",
        bankName: "Wells Fargo",
        bankId: "102000076",
        dummyAccount: true
      };
    }

    return { ...JSON.parse(storedPaymentDetails) };
  });
  const [hasUserLoggedIn, setHasUserLoggedIn] = useState(false);
  const [isCaptureProcessing, setIsCaptureProcessing] = useState(false);
  const [isCaptureCompleted, setIsCaptureCompleted] = useState(false);
  const [hasUserCanceled, setHasUserCanceled] = useState(false);
  const [paymentFlow, setPaymentFlow] = useState("");
  


  function updatePaymentDetails(updatedPaymentDetails) {
    setPaymentDetails(updatedPaymentDetails);

    return localStorage.setItem(
      "Trustly.paymentDetails",
      JSON.stringify(updatedPaymentDetails)
    );
  }

  function toggleHasUserLoggedIn() {
    setHasUserLoggedIn(!hasUserLoggedIn);
  }

  function toggleIsCaptureProcessing() {
    setIsCaptureProcessing((prevValue) => !prevValue);
  }

  function toggleIsCaptureCompleted() {
    setIsCaptureCompleted((prevValue) => !prevValue);
  }

  function toggleHasUserCanceled() {
    setHasUserCanceled(!hasUserCanceled);
  }

  function updatePaymentFlow(updatedPaymentFlow) {
    setPaymentFlow(updatedPaymentFlow);
  }

  return (
    <DeferredPaymentContext.Provider
      value={{
        paymentDetails,
        updatePaymentDetails,
        hasUserLoggedIn,
        toggleHasUserLoggedIn,
        isCaptureProcessing,
        toggleIsCaptureProcessing,
        isCaptureCompleted,
        toggleIsCaptureCompleted,
        hasUserCanceled,
        toggleHasUserCanceled,
        paymentFlow,
        updatePaymentFlow
      }}
    >
      {children}
      <Outlet />
    </DeferredPaymentContext.Provider>
  );
}
