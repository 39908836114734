import { Breadcrumbs } from "../components/Breadcrumbs";
import { DepositFlow } from "../components/templates/DepositFlow";
import { Header } from "../components/Header";
import { useContext, useEffect, useState } from "react";
import { DeferredPaymentContext } from "../context/DeferredPaymentContext";
import { SplashScreen } from "../components/organisms/SplashScreen";
import { OnboardingFlow } from "../components/templates/OnboardingFlow";

export function OnboardingNewUser() {
  const { isCaptureProcessing, isCaptureCompleted } = useContext(
    DeferredPaymentContext
  );
  const [isLoadingDemo, setIsLoadingDemo] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoadingDemo(false), 2000);
  }, []);

  return (
    <div className={`w-full h-full ${isLoadingDemo ? "bg-green-900" : ""}`}>
      <Header
        title={
          isCaptureProcessing || isCaptureCompleted
            ? "Onboarding"
            : "Onboarding"
        }
      />

      {isLoadingDemo ? (
        <SplashScreen merchantName="Bet.on" />
      ) : (
        <div className="container px-6 lg:px-6 xl:px-0 pb-6 mx-auto mt-6">
          <Breadcrumbs title="Onboarding" />
          <OnboardingFlow />
        </div>
      )}
    </div>
  );
}
