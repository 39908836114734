import { useContext } from "react";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";

export function Button({ depositAmount, text = "Deposit Now" }) {
    const { hasUserLoggedIn, paymentDetails, paymentFlow, toggleIsCaptureProcessing, toggleIsCaptureCompleted } = useContext(DeferredPaymentContext);
    async function handleCapture() {
        toggleIsCaptureProcessing();
        
        // if (!paymentDetails.dummyAccount) {
        //   await captureTransaction(
        //     paymentDetails.transactionId,
        //     paymentDetails.merchantReference,
        //     depositAmount
        //   );
        // }
    
        setTimeout(() => {
          toggleIsCaptureProcessing();
          toggleIsCaptureCompleted();
          paymentFlow === "returning" && localStorage.removeItem("Trustly.paymentDetails")
        }, paymentDetails.dummyAccount && 3000)
    
      }

    return (
        <button
            type="button"
            onClick={handleCapture}
            disabled={!hasUserLoggedIn || !depositAmount}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
        >
            {text}
        </button>
    );
}