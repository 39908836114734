import { useContext, useState } from "react";

import { Title } from "../atoms/Title";
import { DepositAmountSelector } from "../organisms/DepositAmountSelector";
import { PaymentMethods } from "../organisms/PaymentMethods";
import { LoadingScreen } from "../organisms/LoadingScreen";
import { SuccessfulScreen } from "../organisms/SuccessfulScreen";

import { DeferredPaymentContext } from "../../context/DeferredPaymentContext";
import { CanceledScreen } from "../organisms/CanceledScreen";
import { Button } from "../atoms/Button";
import { Subtitle } from "../atoms/Subtitle";

import paymentMarks from "../../assets/payment-marks.png";
import { OnboardingButton } from "../atoms/OnboardingButton";
import { Avatar } from "../atoms/Avatar";
import { DevicePhoneMobileIcon, GlobeAmericasIcon, TvIcon } from "@heroicons/react/24/solid";


export function BillingFlow() {
    const [depositAmount, setDepositAmount] = useState("120");
    const { isCaptureProcessing, isCaptureCompleted, hasUserCanceled, hasUserLoggedIn, toggleIsCaptureProcessing, toggleIsCaptureCompleted } =
        useContext(DeferredPaymentContext);

    const [isPaymentMethodsVisible, setIsPaymentMethodsVisible] = useState(false);

    function togglePaymentMethods() {
        setIsPaymentMethodsVisible(prev => !prev);
    }

    function handlePay() {
        toggleIsCaptureProcessing();

        setTimeout(() => {
            toggleIsCaptureProcessing();
            toggleIsCaptureCompleted();
        }, 3000)
    }
    return (
        <>
            {!isCaptureProcessing && isCaptureCompleted ? (
                <SuccessfulScreen title="Payment completed" />
            ) : isCaptureProcessing ? (
                <LoadingScreen title="Processing your payment" />
            ) : hasUserCanceled ? (
                <CanceledScreen title="Payment canceled" />
            ) : (
                <>
                    <Avatar letterIcon={"U"} />

                    <h1 className="text-2xl font-bold text-neutral-strong mt-6 mb-8">
                        {isPaymentMethodsVisible ? 'Payment method' : 'My bill'}
                    </h1>
                    <div className="max-w-1-5xl">
                        {isPaymentMethodsVisible ? (
                            <>
                                <PaymentMethods depositAmount={depositAmount} merchantName={'shop.on'} />
                                <button
                                    type="button"
                                    onClick={togglePaymentMethods}
                                    disabled={!hasUserLoggedIn}
                                    className="mt-4 inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                                >
                                    Continue
                                </button>
                            </>
                        ) : (
                            <>
                                <div>
                                    <div className="flex justify-between">
                                        <span className="text-[#9CA3AF] font-semibold text-base leading-5">Reference</span>
                                        <span className="text-[#9CA3AF] font-semibold text-base leading-5">May 02 - June 01</span>
                                    </div>

                                    <div className="py-4 flex justify-between items-center border-b border-neutral-300">
                                        <div className="w-[3.25rem] p-3.5 rounded-md bg-[#F7F7F7]">
                                            <GlobeAmericasIcon color="#4B5563" />
                                        </div>
                                        <div className="ml-3.5 mr-auto flex flex-col">
                                            <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                                                Wireless Internet
                                            </h2>
                                        </div>
                                        <span className="font-semibold text-neutral-strong text-base leading-5">
                                            $40.00
                                        </span>
                                    </div>

                                    <div className="py-4 flex justify-between items-center border-b border-neutral-300">
                                        <div className="w-[3.25rem] p-3.5 rounded-md bg-[#F7F7F7]">
                                            <DevicePhoneMobileIcon color="#4B5563" />
                                        </div>
                                        <div className="ml-3.5 mr-auto flex flex-col">
                                            <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                                                Phone
                                            </h2>
                                        </div>
                                        <span className="font-semibold text-neutral-strong text-base leading-5">
                                            $40.00
                                        </span>

                                    </div>

                                    <div className="py-4 flex justify-between items-center border-b border-neutral-300">
                                        <div className="w-[3.25rem] p-3.5 rounded-md bg-[#F7F7F7]">
                                            <TvIcon color="#4B5563" />
                                        </div>
                                        <div className="ml-3.5 mr-auto flex flex-col">
                                            <h2 className="mb-1 text-base text-neutral-strong font-semibold leading-5">
                                                TV & Video
                                            </h2>
                                        </div>
                                        <span className="font-semibold text-neutral-strong text-base leading-5">
                                            $40.00
                                        </span>
                                    </div>
                                </div>

                                <div className="border-b border-neutral-300 py-4">
                                    <div className="flex justify-between items-center mb-4">
                                        <span className="text-base text-neutral-strong font-semibold leading-5">Balance due</span>
                                        <span className="text-2xl font-semibold">$120.00</span>
                                    </div>

                                    <div className="flex justify-between">
                                        <span className="text-base text-neutral-strong font-semibold leading-5">Payment due</span>
                                        <span className="text-base text-neutral-strong font-semibold leading-5">Aug 5th</span>
                                    </div>
                                </div>

                                <div className="flex justify-between items-center mt-4">
                                    <span className="text-base text-neutral-strong font-semibold leading-5">Payment method</span>

                                    {hasUserLoggedIn ? (
                                        <div className="flex gap-3 mb-3 items-center text-neutral-600 rounded-md">
                                            <img className="rounded-md" width="32" src={`https://paywithmybank.com/start/assets/institutions/icons/200005501.png`} alt="" />
                                            <span className="text-sm text-[#252A31]">Demo Bank - 6576</span>
                                        </div>
                                    ) : (
                                        <button
                                            className="px-3 py-1 border border-[#CED3D9] rounded-2xl text-sm font-semibold text-[#176669] leading-5 tracking-wide hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80"
                                            onClick={togglePaymentMethods}
                                        >
                                            Add
                                        </button>
                                    )}


                                </div>

                                {hasUserLoggedIn && (
                                    <button
                                        type="button"
                                        onClick={handlePay}
                                        className="mt-4 inline-flex w-full justify-center rounded-md border border-transparent bg-green-800 px-4 py-3 text-base font-semibold text-white shadow-sm hover:bg-green-900 focus:outline-none focus:ring-2 focus:ring-green-800 focus:ring-opacity-20 focus:ring-offset-2 hover:transition-all easy-in delay-80 disabled:opacity-50 disabled:cursor-not-allowed disabled:pointer-events-none"
                                    >
                                        Pay
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                </>
            )}
        </>
    );
}
