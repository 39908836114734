import { Subtitle } from "../atoms/Subtitle";
import { PaymentOption } from "../molecules/PaymentOption";

export function PaymentMethods({ title, depositAmount, merchantName }) {
  return (
    <div className="lg:col-span-2 xl:col-span-1">
      { title && <Subtitle title={title} weight="bold" color="black"/>}

      <div className="mb-2">
        <PaymentOption selected={true} type="onlineBanking" depositAmount={depositAmount} merchantName={merchantName} />
      </div>

      <PaymentOption disabled={true} type="creditCard" />
    </div>
  );
}
